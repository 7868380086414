<template>
  <div>
    <div class="property">
      <div class="label">
        {{ $t('process_editor.element_properties.user_task.name') }}
        <el-input :value="attributes.name" @change="$emit('change-attribute', {
              name: 'name',
              value: $event || null
            })" size="mini"></el-input>
      </div>
    </div>
    <div class="property">
      <div class="label">
        {{ $t('process_editor.element_properties.user_task.multitask') }}
        <el-checkbox :value="isMultiTaskValue" @change="changeMultiTask"></el-checkbox>
      </div>
    </div>
    <template v-if="isMultiTaskValue">
      <div class="property">
        <div class="label">
          {{ $t('process_editor.element_properties.user_task.create_type') }}
        </div>
        <el-select
            size="mini"
            v-model="multiTaskCreateType"
            :placeholder="$t('process_editor.element_properties.user_task.create_type')"
            @input="changeMultiTaskType"
        >
          <el-option
              v-for="item in Object.keys($t('process_editor.element_properties.user_task.create_types'))"
              :key="item"
              :label="$t(`process_editor.element_properties.user_task.create_types.${item}`)"
              :value="item">
          </el-option>
        </el-select>
      </div>
      <div class="property">
        <div class="label">
          {{ $t('process_editor.element_properties.user_task.collection') }}
          <el-input
              size="mini"
              :value="extensions.collection_name"
              @input="changeMultiTaskCollection($event)"
          ></el-input>
        </div>
      </div>
      <div class="property">
        <div class="label">
          {{ $t('process_editor.element_properties.user_task.collection_item') }}
          <el-input
              size="mini"
              :value="extensions.collection_item_name || 'item'"
              @input="changeMultiTaskCollectionItem($event)"
          ></el-input>
        </div>
      </div>
    </template>
    <div class="property">
      <div class="label">
        {{ $t('process_editor.element_properties.user_task.task_registry_id') }}
      </div>
      <registry-select-tree
          type="registry"
          :value="{id: parseInt(extensions.task_registry_id) || null}"
          @change="onExtensionChangeWithAlias('task_registry_id', $event)"
      />
    </div>
    <div class="property">
      <div class="label">
        {{ $t('process_editor.element_properties.user_task.target_registry_id') }}
      </div>
      <registry-select-tree
          type="registry"
          :value="{id: parseInt(extensions.target_registry_id) || null}"
          @change="onExtensionChangeWithAlias('target_registry_id', $event)"
      />
    </div>
    <div v-if="extensions && extensions.task_registry_id && extensions.target_registry_id">
      <div class="property">
        <div class="label">
          {{ $t('process_editor.element_properties.user_task.task_registry_link') }}
        </div>
        <registry-select-tree
            type="xref"
            :value="{id: parseInt(extensions.task_registry_link) || null}"
            :parent-id="parseInt(extensions.task_registry_id) || null"
            @change="onExtensionChangeWithAlias('task_registry_link', $event)"
        />
      </div>
      <div class="property">
        <div class="label">
          {{ $t('process_editor.element_properties.user_task.task_id') }}
        </div>
        <registry-select-tree
            type="field"
            :value="{id: parseInt(extensions['mapping.task_id.field']) || null}"
            :parent-id="parseInt(extensions.task_registry_id) || null"
            @change="onExtensionChangeWithAlias('mapping.task_id.field', $event)"
        />
      </div>
      <div class="property">
        <div class="label">
          {{ $t('process_editor.element_properties.user_task.closing_attribute_id') }}
        </div>
        <registry-select-tree
          type="boolean"
          :value="{id: parseInt(extensions['closing_attribute_id']) || null}"
          :parent-id="parseInt(extensions.task_registry_id) || null"
          @change="onExtensionChangeWithAlias('closing_attribute_id', $event)"
        />
      </div>

      <div class="property">
        <div class="label">
          {{ $t('process_editor.element_properties.user_task.mapping') }}
        </div>

        <field-mapping
          :extensions="extensions"
          :task-registry-id="extensions.task_registry_id"
          :target-registry-id="extensions.target_registry_id"
          @change-extension="$emit('change-extension', $event)"
          @remove-extension="$emit('remove-extension', $event)"
        ></field-mapping>
      </div>

      <div class="property">
        <div class="label">
          {{ $t('task_editor.labels.close_condition') }}
        </div>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-select
                size="mini"
                :value="extensions.close_match_type"
                @input="$emit('change-extension', {name: 'close_match_type', value: $event})"
                :placeholder="$t('task_editor.labels.match_type')">
              <el-option
                  v-for="item in ['all', 'any']"
                  :key="item"
                  :label="$t('task_editor.match_types.' + item)"
                  :value="item">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <logic-tree-elements
                :value="JSON.parse(extensions.rule_target_registry_states || '[]') || []"
                @input="$emit('change-extension', {name: 'rule_target_registry_states', value: JSON.stringify($event)})"
                :placeholder="$t('logic_editor_v2.labels.target_registry_state')"
                :object-id="parseInt(extensions.target_registry_id) || null"
                multiple
                element-type="state">
            </logic-tree-elements>
          </el-col>
          <el-col :span="12">
            <el-select
                size="mini"
                :value="extensions.target_registry_state_match_type"
                @input="$emit('change-extension', {name: 'target_registry_state_match_type', value: $event})"
                :placeholder="$t('task_editor.labels.match_type')">
              <el-option
                  v-for="item in ['all', 'any']"
                  :key="item"
                  :label="$t('task_editor.match_types.' + item)"
                  :value="item">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <logic-tree-elements
                :value="JSON.parse(extensions.rule_task_registry_states || '[]') || []"
                @input="$emit('change-extension', {name: 'rule_task_registry_states', value: JSON.stringify($event)})"
                :placeholder="$t('logic_editor_v2.labels.task_registry_state')"
                multiple
                :object-id="parseInt(extensions.task_registry_id) || null"
                element-type="state">
            </logic-tree-elements>
          </el-col>
          <el-col :span="12">
            <el-select
                size="mini"
                :value="extensions.task_registry_state_match_type"
                @input="$emit('change-extension', {name: 'task_registry_state_match_type', value: $event})"
                :placeholder="$t('task_editor.labels.match_type')">
              <el-option
                  v-for="item in ['all', 'any']"
                  :key="item"
                  :label="$t('task_editor.match_types.' + item)"
                  :value="item">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '../mixins'
import RegistrySelectTree from '@/core/infrastructure/components/RegistrySelectTree.vue'
import FieldMapping from './FieldMapping'

import MultiTaskUtils from '@/services/BPMNEditor/infrastructure/service/MultiTaskUtils'

export default {
  name: 'UserTaskEditor',
  components: { FieldMapping, RegistrySelectTree },
  inject: ['getModeler'],
  mixins: [mixins],
  data () {
    return {
      isMultiTaskValue: MultiTaskUtils.isMultiTask(this.element),
      multiTaskCreateType: MultiTaskUtils.getMultiTaskCreateType(this.element)
    }
  },
  methods: {
    changeMultiTaskCollection (collection) {
      this.$emit('change-extension', { name: `collection_name`, value: collection })
      this.$nextTick(() => {
        this.changeMultiTask(this.isMultiTaskValue, MultiTaskUtils.getMultiTaskCreateType(this.element))
      })
    },
    changeMultiTaskCollectionItem (item) {
      this.$emit('change-extension', { name: `collection_item`, value: item })
      this.$nextTick(() => {
        this.changeMultiTask(this.isMultiTaskValue, MultiTaskUtils.getMultiTaskCreateType(this.element))
      })
    },
    changeMultiTaskType (value) {
      this.changeMultiTask(true, value)
    },
    changeMultiTask (value, type = 'parallel') {
      const collectionName = this.extensions['collection_name']
      const collectionItem = this.extensions['collection_item'] || 'item'
      const element = MultiTaskUtils.buildLoopCharacteristics(this.getModeler(), value, type, collectionName, collectionItem)
      this.$emit('change-attribute', {
        name: 'loopCharacteristics',
        value: element?.businessObject || null
      })

      this.isMultiTaskValue = value
    }
  }
}
</script>
